import React from 'react'
import styles from './demo.module.css'
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';


const settings = {
  dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    arrows: false,
    appendDots: dots => {
      return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={50} />;
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 825,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
    ]

    /* customPaging: function (i) {
      return <div className="" style={{display: 'flex'}}></div>;
  },
  dotsClass: "slick-dots slick-thumb", */
};
const Demo = () => {
  
  return (
<>
<div className={styles.contants}>
      <div className={styles.container}>
        <h2>Our  Services</h2>
        <h4>Lorem ipsum dolor sit amet consectetur. Feugiat ut aliquet sit pellentesque sollicitudin. Lorem ipsum dolor sit amet consectetur. Feugiat ut aliquet sit pellentesque sollicitudin. </h4>
        <div className='service_main'>
        <Slider {...settings}  className='slider' >
          {/* <div className={styles.cards}> */}
          <div className={styles.card13} style={{background:`url('/assets/services/ex49.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Projector</p>
                  <p className={styles.context}>Brand: Epson X49 
                    Manufacturer: Sony <br/>
                    Interface: VGA, HDMI, VGA <br/>
                    Aspect Ratio: 4:3 <br/>
                    Brightness: 3600 Lumens </p>
                    
                  <a href="/projector">
                  <button>know more</button>
                  </a>
                 
              </div>
          </div>
          <div className={`${styles.topGap} ${styles.card2}`} style={{background:`url('/assets/services/two.png')`, backgroundPosition:'center right', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Speakers</p>
                  <p className={styles.context}>Brand: JBL PRX915 <br/>
                  Power: 2000 watts peak/1000 watts RMS each <br/>
                  Max SPL:  133 dB <br/>
                  </p>
                 
                  <a href="/speakers">
                  <button>know more</button>
                  </a>
                 
              </div>
          </div>
          <div className={styles.card3} style={{background:`url('/assets/images/lighting.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Lighting</p>
                  <p className={styles.context}>LED Parcan rental will seamlessly change colour
                  Rent Sharpe Beam Lights & Moving Heads  <br/>
      </p>
                  <a href="/LEDTV">
                  <button>know more</button>
                  </a>
              </div>
          </div>
          <div className={`${styles.topGap} ${styles.card4}`} style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>LED TV</p>
                  <p className={styles.context}>We offer LED TVs for rent in sizes ranging from 40 inches to 75 inches, ensuring that you find the 
      optimal display size for your event. <br/>
      </p>
                  <a href="/lighting">
                  <button>know more</button>
                  </a>
              </div>
          </div>


          <div className={styles.card5}  style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Photography-Videography</p>
                  <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals. 
      </p>
                  <a href="/photography-videography">
                  <button>know more</button>
                  </a>
              </div>
          </div>


          
          <div className={`${styles.topGap} ${styles.card6}`}  style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Shamiana</p>
                  <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals. 
      </p>
                  <a href="/shamiana">
                  <button>know more</button>
                  </a>
              </div>
          </div>


          
          <div className={styles.card7} style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>DJ Packages</p>
                  <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals.
      </p>
                  <a href="/djpackages">
                  <button>know more</button>
                  </a>
              </div>
          </div>


          
          <div className={`${styles.topGap} ${styles.card8}`}  style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Wedding Packages</p>
                  <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals.
      </p>
                  <a href="/weddingpackages">
                  <button>know more</button>
                  </a>
              </div>
          </div>


          
          <div className={styles.card9} style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Live Streaming</p>
                  <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals.
      </p>
                  <a href="/webcasting">
                  <button>know more</button>
                  </a>
              </div>
          </div>


          
          <div className={`${styles.topGap} ${styles.card10}`} style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Flower And Balloon Decorations</p>
                  <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals.
      </p>
                  <a href="/baloon-decoration">
                  <button>know more</button>
                  </a>
              </div>
          </div>
          
          <div className={styles.card11}  style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>Catering</p>
                  <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals.
      </p>
                  <a href="/catering">
                  <button>know more</button>
                  </a>
              </div>
            </div>
          
          <div className={`${styles.topGap} ${styles.card12}`} style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              <div className={styles.bg}>
                  <p className={styles.head}>T-Shirts And Corporate Gifts</p>
                  <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals.
      </p>
                  <a href="/corporate">
                  <button>know more</button>
                  </a>
              </div>
            </div>

        {/*   </div> */}
        </Slider>
        </div>
    </div>
    </div>
    </>
  )
}

export default Demo    




// <div className={`${styles.topGap} ${styles.card12}`} style={{background:`url('/assets/services/lgtv.png')`, backgroundPosition:'right center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
// <div className={styles.bg}>
//     <p className={styles.head}>T-Shirts And Corporate Gifts</p>
//     <p className={styles.context}>Elevate your experience with just a click by renting top-tier equipment from Shriram Visuals.
// </p>
//     <a href="/corporate">
//     <button>know more</button>
//     </a>
// </div>
// </div>

// {/*   </div> */}
// </Slider>
// </div>
// </div>
// </div>
// </>
// )
// }

// export default Demo    